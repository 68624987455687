export const liveVideo = {
    "title": "Now Playing - Limdi Gopal Sampraday’s Shashan Ratna Pujya Shri Ram Uttam Muni Maharajsaheb on One Jain",
    "videoid": "yg7EE8qh9WY",
    "live": true,
    "promoted_link": null,
    "promoted_link_title": null
}

export const sanghs = [{
        groupName: 'Mumbai And Maharashtra',
        list: [{
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Kesarvadi',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Hingwala Lane, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Borivali (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwai Jain Shravak Sangh Dadar West',
                phone: null
            },
            {
                name: 'Shree Vileparle Vardhman Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Sheth Damji Laxmichand Jain Dharma Sthanak, Chinchpokali',
                phone: null
            },
            {
                name: 'Shree  Vardhman Sthanakwasi Jain Shravak Sangh, Kandivali (Moto Upashray)',
                phone: null
            },
            {
                name: 'Shree Vardhaman Sthanakwasi Jain Shravak Sangh, Santacruz West',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Tardeo',
                phone: null
            },
            {
                name: 'Shri Ghatkopar Agra Road Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Yogi Nagar, Borivali (West)',
                phone: null
            },
            {
                name: 'Shree Goregaon Sthanakwasi Jain Sangh (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Verma Nagar, Andheri (East)',
                phone: null
            },
            {
                name: 'Shree Shwetambar Sthanakwasi Jain Sangh, Manekpur',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Garodiya Nagar, Ghatkopar',
                phone: null
            },
            {
                name: 'Shri Kamagali Vardhman Sthanakwasi Jain Shravak Sangh, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Swadhyay Sangh, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Rajavadi Vardhman Sthankvasi Jain Shravak Sangh, Ghatkopar',
                phone: null
            },
            {
                name: 'Shri Pantnagar Vardhman Sthanakwasi Jain Shravak Sangh, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Vardhmaan Sthankvasi Jain Sangh -Pawandham Kandivali (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh - Parasdham, Ghatkopar',
                phone: null
            },
            {
                name: 'Shri Mahavir Sthanakwasi Jain Sangh, Dombivali (East)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthankvasi Jain Sangh- Danhanuvadi,Kandivali (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Mahavir Nagar, Kandivali',
                phone: null
            },
            {
                name: 'Shree Mira Road Vardhman Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'New Bombay Sthanakwasi Jain Sangh, Vashi',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Mulji Nagar, Borivali (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Kandivali (East)',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Dolat Nagar, Borivali (East)',
                phone: null
            },
            {
                name: 'Kutchi Visa Oswal Sthanakwasi Jain Sangh, Dahisar',
                phone: null
            },
            {
                name: 'Shree Narsing Lane Vardhman Sthankvai Jain Sangh, Malad (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Mandal, Malad (East)',
                phone: null
            },
            {
                name: 'Shree Bhayandar Vardhman Sthanakwasi Jain Shravak Sangh',
                phone: null
            },
            {
                name: 'Shri Ajaramar Jain Upashray, Kurla (West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Lokhandwala',
                phone: null
            },
            {
                name: 'Shree Sarvodaya Vardhman Sthanakwasi Jain Shravak Sangh, Mulund',
                phone: null
            },
            {
                name: 'Shree Ajramar Jain Dharm Sthanak, Navagam- Dahisar(West)',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthankvasi Jain Sangh, Ravalpada, Dahisar',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Shravak Sangh, Chembur',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Sangh, Tilak Nagar, Ghatkopar',
                phone: null
            },
            {
                name: 'Shri Powai Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Dahisar Sthanakwasi Jain Yuvak Mandal',
                phone: null
            },
            {
                name: 'Shree Gorai Kutchi Visa Oswal Jain Sangh, Borivali',
                phone: null
            },
            {
                name: 'Shree Kutchi Visa Oswal Sthanakwasi Jain Sangh, Borivali (East)',
                phone: null
            },
            {
                name: 'Arham Araadhna Kendra Narsing Lane, Malad West',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Akola',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Amravati',
                phone: null
            },
            {
                name: 'Shree Shvetamber Sthankvasi Jain Sangh, Latur',
                phone: null
            },
            {
                name: 'Shree Saurashtra Dashashrimali Jain Vanik Sangh, Yavatmal',
                phone: null
            },
            {
                name: 'Shree Vardhman Shwetambar Sthanakwasi Jain Shravak Sangh, Vardha',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Shravak Sangh, Chandrapur',
                phone: null
            },
            {
                name: 'Shree Saurastra Sthankvasi  Jain Mitra Mandal, Pune',
                phone: null
            },
            {
                name: 'The Goa Jain Sthanakwasi Sangh',
                phone: null
            },
            {
                name: 'Shri Swetamber Sthanakwasi Jain Sangh, Kalyan',
                phone: null
            },
        ]
    },
    {
        groupName: 'Gujarat',
        list: [{
                name: 'Shree Gondal Navagadh Sthanakwasi Jain Sangh-Gondal',
                phone: null
            },
            {
                name: 'Shree Royal Park Sathankwasi Jain Mota Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Shalibhadra Sardarnagar Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Manhar Plot Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Mahavirnagar Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Jainchal Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Sheth Upashray Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Gitgurjari Sthanakwasi Jain Upashray, Rajkot',
                phone: null
            },
            {
                name: 'Shree Neminath Vitrag Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Gondal Road (West) Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Racecourse Park Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shree Aadin Th Sthankvasi Jain Religious Trust- Nalanda Upasary, Rajkot',
                phone: null
            },
            {
                name: 'Shree Shramjivi Sthankvasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Uvasaggaharam Sadhna Bhavan, Rajkot',
                phone: null
            },
            {
                name: 'Shree Shramjivi Sthanakwasi Jain Sangh-Rajkot, Rajkot',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh Junagadh',
                phone: null
            },
            {
                name: 'Shree Sanghani Sthankvasi Jain Sangh, Gondal',
                phone: null
            },
            {
                name: 'Shree Vardhaman Sthanakwasi Jain Sangh, Vasna, Ahmedabad',
                phone: null
            },
            {
                name: 'Shree Shahibag  Sthanakwasi Jain Sangh, Ahmedabad',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Bopal, Ahmedabad',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Gandhinagar',
                phone: null
            },
            {
                name: 'Shri Alkapuri Sthanakwasi Jain Sangh, Vadodara',
                phone: null
            },
            {
                name: 'Shree Sthankwasi Jain Sangh, Shastripol, Vadodara',
                phone: null
            },
            {
                name: 'Shree Indrapuri Sthanakwasi Jain Sangh, Vadodara',
                phone: null
            },
            {
                name: 'Shree Sthankwasi Jain Sangh, Nizampura, Vadodara',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Manjalpur, Vadodara',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Bajwa, Vadodara',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Gorva, Vadodara',
                phone: null
            },
            {
                name: 'Shree Pawandham Jain Sangh- Vadodara',
                phone: null
            },
            {
                name: 'Shree Shwetambar Sthanakwasi Jain Sangh, Karjan',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Anand',
                phone: null
            },
            {
                name: 'Shree Dashashrimali Sthanakwasi Jain Sangh-Dhari',
                phone: null
            },
            {
                name: 'Shree Bagasra Sthanakvasi Jain Sangh,',
                phone: null
            },
            {
                name: 'Shree Porbandar Sthankvasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Visavdar Sthanakvasi Jain Sangh,',
                phone: null
            },
            {
                name: 'Shree Veraval Sthankvasi Jain Sangh, Veraval',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Una',
                phone: null
            },
            {
                name: 'Shree Sawarkundla Thanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Chalala Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Boghabhai Sthanakwasi Jain Sangh, Jetpur',
                phone: null
            },
            {
                name: 'Shree Mendarda Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Maadiya Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Chandraprabhu Sthanakwasi Jain Sangh, Rajkot',
                phone: null
            },
            {
                name: 'Shri Sthanakwasi Jain Sangh, Amreli',
                phone: null
            },
        ]
    },
    {
        groupName: 'North And East India',
        list: [{
                name: 'Shree Delhi Gujarati Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shri Vardhman Sthanakwasi Jain Gujarati Sangh, Raipur',
                phone: null
            },
            {
                name: 'Shree Dashashrimali Sthanakwasi Jain Sangh, Bistupur',
                phone: null
            },
            {
                name: 'Shri Gujarati Sthanakwasi Sakchi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Ranchi Gujarati Samaj',
                phone: null
            },
            {
                name: 'Shree Banaras Sthanakwasi Sangh',
                phone: null
            },
            {
                name: 'Shree Patna Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Vardhman Sthanakwasi Jain Sangh, Howrah',
                phone: null
            },
            {
                name: 'Shree Shwetambar Sthanakwasi Jain Sangh, Jharia',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh Asansol',
                phone: null
            },
            {
                name: 'Shri Liluah Sthanakwasi Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Raniganj Jain Shwetambar Sthanakwasi Sangh',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Chas ',
                phone: null
            },
            {
                name: 'Shree Katras Shwetambar Sthanakwasi (Gujarati) Jain Sangh',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Kharagpur',
                phone: null
            },
            {
                name: 'Shree Dashashrimali Vardhman Sthanakwasi Jain Sangh, Cuttack',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Bermo',
                phone: null
            },
            {
                name: 'Parasdham, Kolkata',
                phone: null
            },
            {
                name: 'Bardhman Sthanakwasi Jain Samaj',
                phone: null
            },
            {
                name: 'Shree Gurjar Jain Sangh, Rourkela',
                phone: null
            },
            {
                name: 'Shree Jain Shwetamber Sthanakwasi Sangh, Kanpur',
                phone: null
            },
        ]
    },
    {
        groupName: 'International Sanghs',
        list: [{
                name: 'Navnat Vanik Association Of The United Kingdom',
                phone: null
            },
            {
                name: 'Jain Samaj Manchester',
                phone: null
            }, {
                name: 'Jain Centre Of South Florida',
                phone: null
            },
            {
                name: 'Jain Society Of Metropolitan, Washington',
                phone: null
            },
            {
                name: 'Parshwanath Jain Center Of Washington State, Seattle',
                phone: null
            },
            {
                name: 'Jain Society Of Northern Virginia',
                phone: null
            },
            {
                name: 'Jain Centre Of Northern California',
                phone: null
            },
            {
                name: 'Shree Sthanakwasi Jain Sangh, Uganda',
                phone: null
            },
            {
                name: 'Gujarati Association, Kuala Lumpur,Malaysia',
                phone: null
            },
        ]
    },
    {
        groupName: 'Sansthas',
        list: [{
                name: 'Jain Jagruti Center',
                phone: null
            },
            {
                name: 'Jain Social Group',
                phone: null
            }, {
                name: 'Shree Kathiyavad Sthanakwasi Jain Samaj, Ahmedabad',
                phone: null
            },
            {
                name: 'Calcutta Zalawadi Sthanakwasi Jain Mitra Mandal',
                phone: null
            },
            {
                name: 'Jain Social Group - Sangini Forum',
                phone: null
            },
            {
                name: 'Shree Kathiyavad Sthanakwasi Jain Samaj, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Kathiyavad Sthanakwasi Jain Samaj Mahila Mandal, Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Zalavadi Sthanakwasi Jain Mitra Mandal, Andheri',
                phone: null
            },
            {
                name: 'Shree Kathiyavad Seva Samaj Mahila Mandal. Ghatkopar',
                phone: null
            },
            {
                name: 'Shree Kathiyavad Seva Samaj, Ghatkopar',
                phone: null
            },
            {
                name: 'Sahiyari Ladies Group',
                phone: null
            },
            {
                name: 'Shree Navkar Aaradhak Trust',
                phone: null
            },
            {
                name: 'Pran Guru Sthanakwasi Jain Gurukul, Vadiya',
                phone: null
            }
        ]
    },
]


export const dailySchedule = [{
        "title": "Inner Cleaning Meditation Course",
        "date": "19",
        "day": "SUN",
        "persion": "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "20",
        "day": "MON",
        "persion": "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "21",
        "day": "TUES",
        "persion": "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
    {
        "title": "Inner Cleaning Meditation Course",
        "date": "22",
        "day": "WEN",
        "persion": "Rashtrasant Param Gurudev Shree Namramuni Maharaj Saheb"
    },
]